import React from "react";
import IFramedExperience from "../../components/iframed-experience";
import { EXPERIENCES } from "../../components/store";

const Experience = () => {
    return <IFramedExperience fadeIn experience={EXPERIENCES.EARTH_WITH_ISS}
     hTitle="Earth ISS" hContent="Earth with ISS" hUrl="earth-iss" />;
};

export default Experience;
